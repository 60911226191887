// third-party

// assets

import {
    IconBug,
    IconKey,
    IconUser,
    IconClipboardList,
    IconDashboard,
    IconInfoSquareRounded,
    IconArchive,
    IconReportMoney,
    IconMessage2Bolt,
    IconReceipt
} from '@tabler/icons-react';
import { FormattedMessage } from 'react-intl';

// constant
const icons = { IconBug, IconKey, IconUser, IconClipboardList, IconDashboard, IconInfoSquareRounded, IconArchive, IconReceipt };

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'management',
    title: 'Admin Management',
    icon: icons.IconKey,
    type: 'group',
    children: [
        {
            id: 'admin_dashboard',
            title: <FormattedMessage id="Dashboard" defaultMessage="Dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'users',
            title: 'User Management',
            type: 'collapse',
            icon: icons.IconUser,
            children: [
                {
                    id: 'list',
                    title: 'List Users',
                    type: 'item',
                    url: '/users/list'
                },
                {
                    id: 'add',
                    title: 'Add User',
                    type: 'item',
                    url: '/users/add'
                }
            ]
        },
        {
            id: 'projects',
            title: 'Project Management',
            type: 'collapse',
            icon: icons.IconClipboardList,
            children: [
                {
                    id: 'developmentProjects',
                    title: 'Development Projects',
                    type: 'item',
                    url: '/projects/dev-projects'
                },
                {
                    id: 'dmrvProjects',
                    title: 'dMRV Projects',
                    type: 'item',
                    url: '/projects/dMRV-projects'
                }
            ]
        },
        {
            id: 'applications',
            title: 'Finance Applications',
            type: 'item',
            url: '/applications/list',
            icon: IconReportMoney
        },
        {
            id: 'technical_support_requests',
            title: 'Technical Support Requests',
            type: 'item',
            url: '/technical-support-requests',
            icon: IconBug
        },
        {
            id: 'investor_feedbacks',
            title: 'Investor Feedback',
            type: 'item',
            url: '/investor-feedbacks',
            icon: IconMessage2Bolt
        },
        {
            id: 'marketplace',
            title: 'Marketplace Listings',
            type: 'collapse',
            icon: icons.IconClipboardList,
            children: [
                {
                    id: 'manageListings',
                    title: 'Manage Listings',
                    type: 'item',
                    url: '/marketplace/listings'
                }
            ]
        },
        {
            id: 'fpic_reports',
            title: 'FPIC Reports',
            type: 'item',
            url: '/fpic-reports/list',
            icon: icons.IconArchive,
            breadcrumbs: false
        },
        {
            id: 'pay-go_payments',
            title: 'Pay-Go Payments',
            type: 'item',
            url: '/paygo-payments/list',
            icon: icons.IconReceipt,
            breadcrumbs: false
        },
        {
            id: 'interest_forms',
            title: <FormattedMessage id="Interest Forms" defaultMessage="Interest Forms" />,
            type: 'item',
            url: '/interest-forms/list',
            icon: icons.IconInfoSquareRounded,
            breadcrumbs: false
        }
    ]
};

export default pages;
